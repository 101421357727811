/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Vanta from "../../../components/Vanta";
import Divider from "@mui/material/Divider";
import colors from "../../../assets/theme/base/colors";

function Projects() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Vanta
        minHeight="75vh"
        width="100%"
        backgroundSize="cover"
        backgroundPosition="top"
        display="grid"
        placeItems="center"
        bgVanta="dark"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="secondary"
              fontWeight="regular"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
              })}
            >
              Athenstaedt Technology{" "}
            </MKTypography>
          </Grid>
        </Container>
      </Vanta>
      <Container style={{ mt: "20px" }}>
        <Grid container item xs={12} my={0} m={0} spacing={0}>
          <Grid item xs={12} lg={2} />
          <Grid item xs={12} lg={8}>
            <MKBox>
              <MKTypography
                variant="h1"
                color="black"
                fontWeight="regular"
                align="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["4xl"],
                  },
                })}
              >
                Data
              </MKTypography>
              <Divider
                variant="middle"
                sx={{
                  background: colors.accent.default,
                  opacity: "1",
                  height: "1px",
                }}
              />
              <MKTypography align="justify">
                This project branch is relatively new in this context, with the aim to build up a
                set of abstract functionality based on gathered knowledge at e.less-related and
                other projects to accomplish data processing tasks. Some of this tasks will be data
                classification and -extraction out of arbitrary file formats and -layouts as well as
                processing the extracted data in different - more application-specific - ways using
                external data. One application out of many is predicting measurement data given a
                knowledge bays of physics, boundaries and - if existing - verified external that
                acts as second measurement set, to predict future behaviour of the inspected system.
              </MKTypography>
              <MKTypography align="justify">
                A huge demand for this kind of software can be found in nearly every type of
                business today, dealing with any type of resources: logistics, human resources,
                natural resources, energy. Given e.less as an example, the customer drops a load
                profile he was given by his current energy supplier, which he himself might not have
                an idea of its contents. The software needs to classify the data contained in this
                file without any human interaction and extract the necessary values for further
                processing. Moving forward, the processing - in best case - takes weather data, the
                customer's business sector, stock data, etc. into account for calculating a
                prediction of the customer's future consumption pattern, which itself builds the
                foundation of any priced offers.
              </MKTypography>
              <MKTypography align="justify">
                This example clearly shows the demand for data-exploration software in many
                different areas and roughly structures the structure for this project branch:
                classifcation/extraction and exploitation/prediction.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={2} />
        </Grid>
      </Container>
      <MKBox pt={1} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Projects;
