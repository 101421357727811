/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Vanta from "../../../components/Vanta";
import RotatingCard from "../../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../../examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../examples/Cards/RotatingCard/RotatingCardBack";
import data from "./data";

function Projects() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Vanta
        minHeight="75vh"
        width="100%"
        backgroundSize="cover"
        backgroundPosition="top"
        display="grid"
        placeItems="center"
        bgVanta="dark"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="secondary"
              fontWeight="regular"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
              })}
            >
              Athenstaedt Technology{" "}
            </MKTypography>
          </Grid>
        </Container>
      </Vanta>
      <Container style={{ mt: "20px" }}>
        <Grid container item xs={12} mt={0} mz={2} spacing={2} alignItems="stretch">
          {data.map((item) => (
            <Grid item xs={12} lg={4}>
              <RotatingCard sx={{ display: "flex" }}>
                <RotatingCardFront
                  color="dark"
                  image={item.image}
                  icon={item.icon}
                  title={item.title}
                  description={item.teaser}
                />
                <RotatingCardBack
                  color="secondary"
                  image={item.image}
                  title={item.heading}
                  description={item.content}
                />
              </RotatingCard>
            </Grid>
          ))}
        </Grid>
      </Container>
      <MKBox pt={1} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Projects;
