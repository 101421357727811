/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Vanta from "../../components/Vanta";
import Divider from "@mui/material/Divider";
import colors from "../../assets/theme/base/colors";
import data from "./data";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

function Imprint() {
  const header = data.header;
  const content = data.content;

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Vanta
        minHeight="75vh"
        width="100%"
        backgroundSize="cover"
        backgroundPosition="top"
        display="grid"
        placeItems="center"
        bgVanta="dark"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="secondary"
              fontWeight="regular"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
              })}
            >
              Athenstaedt Technology{" "}
            </MKTypography>
          </Grid>
        </Container>
      </Vanta>
      <Container style={{ mt: "20px" }}>
        <Grid container item xs={12} my={0} m={0} spacing={0}>
          <Grid item xs={12} lg={2} />
          <Grid item xs={12} lg={8}>
            <MKBox>
              <MKTypography
                variant="h1"
                color="black"
                fontWeight="regular"
                align="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["4xl"],
                  },
                })}
              >
                Imprint
              </MKTypography>
              <Divider
                variant="middle"
                sx={{
                  background: colors.accent.default,
                  opacity: "1",
                  height: "1px",
                }}
              />
              <MKTypography>
                <b>Angaben gemäß § 5 TMG</b>
                <br />
                {header.name} <br />
                {header.streetHouse} <br />
                {header.zipCity} <br />
                <br />
                <b>Vertreten durch:</b> <br />
                {header.responsible} <br />
                <br />
                <b>Kontakt:</b> <br />
                {header.contact.email} <br />
              </MKTypography>
              {content.map((item) => (
                <>
                  <MKTypography variant="h3">{item.title}</MKTypography>
                  <MKTypography align="justify">{item.text}</MKTypography>
                  <br />
                </>
              ))}
              <MKTypography>
                This page is heavily based on the following projects:{" "}
                <Link href={"https://www.creative-tim.com/product/material-kit-react"}>
                  Creative Tim
                </Link>{" "}
                & <Link href={"https://www.vantajs.com/"}>Vanta JS</Link> both thankfully provided
                under MIT licence <Icon>favorite</Icon>
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={2} />
        </Grid>
      </Container>
      <MKBox pt={1} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Imprint;
