const data = [
  {
    title: "Data",
    content: [
      [
        "Athenstaedt Technology is <i>founded</i> by Tobias Athenstaedt with the purpose of " +
          "structuring projects, demonstrating and documenting results as well as delivering a " +
          "central contact for anyone having interest. Following this core idea, Athenstaedt " +
          "Technology - by now - is not a company or anything similar, in fact it is more a " +
          "project itself. Due to different external influences, this page is not maintained in " +
          "a regular manner, though it should provide the possibility to have some insight in " +
          "current and / or former projects. While not existing currently, some projects may " +
          "come up in a github repository. By now, this site does not provide you with any " +
          "industrial-level software, instead it does show some personal approaches to " +
          "different real world problems.",
        "Currently I am working for my Bachelor o.S. in computer science at the Technische " +
          "Universität München with a focus on Aeronautics & Space Engineering. This probably " +
          "is an explanation for the accumulation of drone-related projects in the past, even " +
          "though a significant impact of AI technology lectures can be seen in the last time " +
          "looking at the data exploration topics. Apart from studying I am a voluntary " +
          "employee at the german Red Cross in the telecommunications services unit.",
      ],
      [
        "Since '17 the e.less Gmbh dominated the time left for aeronautics related projects, " +
          "switching the focus step by step towards data exploration. e.less is a german " +
          "startup in the energy business I was involved in, believing in transparency, " +
          "accuracy and (time-) performance during the tendering process in the business " +
          "sector. e.less combines this keywords in a microservice oriented architecture " +
          "providing services from data-reading, -recognition and -processing as well as " +
          "different prediction techniques necessary for any tendering in the german b2b energy " +
          "business up to a set of platforms for more direct communication of suppliers, " +
          "customers and any involved third parties.",
        "Though leaving less time for aeronautic related projects, this circumstances may " +
          "have positive effect on some of those projects by developing growing ideas of using " +
          "some kind of similar techniques for log-data exploration and AI based flight " +
          "algorithm improvements.",
      ],
    ],
  },
  {
    title: "Keyfacts",
    content: [
      {
        interval: true,
        from: "'13",
        to: undefined,
        description: "voluntarily employed at german Red Cross telecommunications services unit",
      },
      {
        interval: false,
        from: "'17",
        to: undefined,
        description: "Abitur",
      },
      {
        interval: true,
        from: "'17",
        to: undefined,
        description:
          "studying general informatics at Technische Universität München with focus on aeronautics & space engineering",
      },
      {
        interval: true,
        from: "'17",
        to: "'21'",
        description: "employed at e.less Systems GmbH Augsburg",
      },
      {
        interval: true,
        from: "'21",
        to: undefined,
        description: "developing smart-grid-related interfaces",
      },
    ],
  },
];

export default data;
