// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
// import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logo from "assets/images/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Athenstaedt Technology",
    image: logo,
    route: "/",
  },
  socials: [
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
  ],
  menus: [
    {
      name: "Projects",
      items: [
        { name: "Robotics", route: "/projects/robotics" },
        { name: "Data", route: "/projects/data" },
      ],
    },
    {
      name: "About",
      items: [
        { name: "about", route: "/about" },
        { name: "imprint", route: "/imprint" },
      ],
    },
    {
      name: "Contact",
      items: [{ name: "contact us", route: "/contact" }],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by Tobias Athenstaedt
    </MKTypography>
  ),
};
