/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Vanta from "../../components/Vanta";
import Divider from "@mui/material/Divider";
import colors from "../../assets/theme/base/colors";
import data from "./data";

function About() {
  const dataText = data[0];
  const keyfacts = data[1];
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Vanta
        minHeight="75vh"
        width="100%"
        backgroundSize="cover"
        backgroundPosition="top"
        display="grid"
        placeItems="center"
        bgVanta="dark"
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="secondary"
              fontWeight="regular"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
              })}
            >
              Athenstaedt Technology{" "}
            </MKTypography>
          </Grid>
        </Container>
      </Vanta>
      <Container style={{ mt: "20px" }}>
        <Grid container item xs={12} my={0} m={0} spacing={0}>
          <Grid item xs={12} lg={2} />
          <Grid item xs={12} lg={8}>
            <MKBox>
              <MKTypography
                variant="h1"
                color="text"
                fontWeight="regular"
                align="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["4xl"],
                  },
                })}
              >
                About us
              </MKTypography>
              <Divider
                variant="middle"
                sx={{
                  background: colors.accent.default,
                  opacity: "1",
                  height: "1px",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={2} />
        </Grid>
        <Grid container item xs={12} my={0} ml={5} m={0} spacing={0}>
          <Grid item xs={1} lg={2} />
          {dataText.content.map((item) => (
            <Grid item xs={12} lg={4}>
              {item.map((paragraph) => (
                <MKTypography align="justify" dangerouslySetInnerHTML={{ __html: paragraph }} />
              ))}
            </Grid>
          ))}
          <Grid item xs={1} lg={2} />
        </Grid>
      </Container>
      {/* keyfacts */}
      <Container style={{ mt: "20px" }}>
        <Grid container item xs={12} my={0} m={0} spacing={0}>
          <Grid item xs={12} lg={2} />
          <Grid item xs={12} lg={8}>
            <MKBox>
              <MKTypography
                variant="h1"
                color="text"
                fontWeight="regular"
                align="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["4xl"],
                  },
                })}
              >
                Keyfacts
              </MKTypography>
              <Divider
                variant="middle"
                sx={{
                  background: colors.accent.default,
                  opacity: "1",
                  height: "1px",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={2} />
        </Grid>
        <Grid container item xs={12} my={0} m={0} spacing={0}>
          <Grid item xs={1} lg={2} />
          <Grid item xs={1} lg={8}>
            <table>
              {keyfacts.content.map((item) => (
                <tr>
                  <td width={"15%"}>
                    <MKTypography fontWeight={"bold"}>
                      {item.interval && item.to !== undefined
                        ? item.from + " - " + item.to
                        : item.interval
                        ? item.from + " -"
                        : item.from}
                      :
                    </MKTypography>
                  </td>
                  <td>
                    <MKTypography>{item.description}</MKTypography>
                  </td>
                </tr>
              ))}
            </table>
          </Grid>
          <Grid item xs={1} lg={2} />
        </Grid>
      </Container>
      <MKBox pt={1} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default About;
