import React from "react";
import NET from "vanta/dist/vanta.net.min";
import * as THREE from "three";
import PropTypes from "prop-types";
import colors from "../../assets/theme/base/colors";

class Vanta extends React.Component {
  constructor(props) {
    super(props);
    this.vantaRef = React.createRef();
  }

  componentDidMount() {
    const { background } = colors;
    const { bgVanta } = this.props;

    this.vantaEffect = NET({
      el: this.vantaRef.current,
      color: 0x615257,
      backgroundColor: bgVanta === "dark" ? background.dark : background.default,
      points: 15,
      maxDistance: 30,
      THREE,
    });
  }

  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy();
  }

  render() {
    const { children } = this.props;

    return (
      <div style={this.props} ref={this.vantaRef}>
        {children}
      </div>
    );
  }
}

Vanta.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Vanta;
