import bgImage from "../../../assets/images/rotating-card-bg-back.jpeg";

const data = [
  {
    title: "Modular FC Architecture",
    teaser: "- WIP -",
    heading: "Modular FC Architecture",
    content:
      "A WIP project facing the idea of using arbitrary hardware components to setup a multirotor flight controller.",
    icon: "touch-app",
    image: bgImage,
  },
  {
    title: "Multi-Platform FC-Framework",
    teaser: "- WIP -",
    heading: "Multi-Platform FC-Framework",
    content:
      "This multi-platform flight controlling framework's purpose is making data-logging, -evaluation and -logging as " +
      "well as improving the flight-controllers capabilities independent from operating systems, etc. by " +
      "providing a standardized interface.",
    icon: "touch-app",
    image: bgImage,
  },
  {
    title: "Miniature-V2X Protocol",
    teaser: "- WIP -",
    heading: "Mini-V2X",
    content:
      "Miniaturized Vehicle-to-X is a set of communication protocols currently developed with the idea of providing a " +
      "simple start in Vehicle-Vehicle, Vehicle-Infrastructure, etc. communications. The framework it pretends to be, " +
      "does not target many real-world challenges at once but shows the capabilities of different approaches via " +
      "data-visualization and physical demonstration with multiple flying agents and ground stations.",
    icon: "touch-app",
    image: bgImage,
  },
];

export default data;
